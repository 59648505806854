<template>
  <div>
    <div class="card p-2 bg-card">
      <div class="align-center-flex">
        <div class="d-flex">
          <div class="mr-1">
            <input
              v-model="searchResult"
              placeholder="Search"
              class="form-control input-sm flex mr-1"
              style="min-width:200px"
            />
          </div>
          <select
            name=""
            id=""
            class="form-control mr-1"
            style="min-width: 200px"
            v-model="selectedassetcat"
            @change="changeAssetcat"
          >
            <option value="" selected >Select Asset Category</option>
            <option
              :value="assetcat._id"
              v-for="assetcat in allAssets"
              :key="assetcat._id"
            >
              {{ assetcat.name }}
            </option>
          </select>

          <select
            name=""
            id=""
            class="form-control mr-1"
            style="min-width: 200px"
            v-model="selectedthreat"
            @change="changeThreat"
          >
            <option value="" selected >Select Threat</option>
            <option
              :value="threat._id"
              v-for="threat in allThreat"
              :key="threat._id"
            >
              {{ threat.name }}
            </option>
          </select>
          <select
            name=""
            id=""
            class="form-control mr-1"
            style="min-width: 200px"
            v-model="selectedvul"
            @change="changeVul"
          >
            <option value="" selected >Select Vulnerability</option>
            <option
              :value="vul._id"
              v-for="vul in allVulnerabilities"
              :key="vul._id"
            >
              {{ vul.name }}
            </option>
          </select>
        </div>
        <div>
          <button
            @click="addRisk()"
            class="btn pointer btn-outline-primary btn-sm margin-right"
            :disabled="!this.$store.state.auth.permissions.includes('risk-create')"
          >
            + Add
          </button>
        </div>
      </div>
    </div>
    <b-card>
      <div class="user-select">
        <div class="card-company-table card card-congratulation-medal">
          <div class="mb-0 table-responsive border rounded">
            <table role="table" class="table b-table table-hover">
              <thead role="rowgroup header">
                <tr role="row">
                  <th role="columnheader" scope="col">#</th>
                  <th role="columnheader" scope="col">Name</th>
                  <!-- <th role="columnheader" scope="col">Description</th> -->
                  <!-- <th role="columnheader" scope="col">Vulnerabilities</th> -->
                  <th role="columnheader" scope="col" class="text-align-right">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="rowgroup">
                <tr
                  v-for="(risk, index) in allRisk"
                  :key="risk._id"
                  role="row"
                  class="custom-table-row"
                >
                <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                  <td role="cell">{{ risk.name }}</td>
                  <!-- <td role="cell">{{ risk.description }}</td> -->
                  <!-- <td v-for="data in vulnerabilityData" role="cell">{{ data.name }}</td> -->

                  <td role="cell" class="text-align-right">
                    <span
                      @click="viewRisk(risk._id)"
                      class="icon-box-new-risk pointer"
                    >
                      <feather-icon class="rotate" icon="EyeIcon" size="20" />
                    </span>
                    <span
                      @click="showModal(risk._id)"
                      class="icon-box-new-risk pointer"
                    >
                      <feather-icon
                        class="delete-color rotate"
                        icon="Trash2Icon"
                        size="20"
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="card-body">
              <b-modal
                id="modal-danger"
                ref="modal-danger"
                ok-only
                ok-variant="danger"
                @ok="deleteRisk()"
                modal-class="modal-danger"
                centered
                title="Delete !"
                :ok-disabled="!this.$store.state.auth.permissions.includes('risk-delete')"
              >
                <b-card-text> Are You Sure to Delete the Risk ? </b-card-text>

                <!-- <button @click="deleteUser(item._id)"></button> -->
              </b-modal>
            </div>
          </div>
        </div>
        <div class="float-right">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          >
          </b-pagination>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import {
  BFormTextarea,
  VBToggle,
  BPagination,
  BCard,
  BModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data: () => ({
    currentPage: 1,
    lastPage: null,
    perPage: 10,
    rows: 1,
    searchResult: "",
    allRisk: [],
    allVulnerabilitiesData: [],
    allDataVulnearbilty: [],
    threatData: [],
    vulnerabilityData: [],
    controlData: [],
    riskDeleteID: "",
    selectedDataType: [],
    selectedassetcat: "",
    selectedthreat: "",
    selectedvul: "",
    allAssets: [],
    allThreat: [],
    allVulnerabilities: [],
  }),
  components: {
    BFormTextarea,
    BPagination,
    BCard,
    BModal,
  },

  props: {},
  watch: {
    currentPage(newPage) {
      this.getRisk(newPage);
    },
    searchResult() {
      this.getSearchByName(this.currentPage);
    },
  },
  async mounted() {
    this.allVulnerabilitiesData = await this.getVulnerabilities();
    this.getRisk(this.currentPage);
    this.getassetcat();
    this.getthreat();
    this.getvul();
  },
  computed: {},
  methods: {
    changeVul() {
      this.getSearchByName(this.currentPage);
    },
    changeThreat() {
      this.getSearchByName(this.currentPage);
    },
    changeAssetcat() {
      this.getSearchByName(this.currentPage);
    },
    getassetcat() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/ast_categories`,
      };
      this.$http(options)
        .then((res) => {
          this.allAssets = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getthreat() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/threats`,
      };
      this.$http(options)
        .then((res) => {
          this.allThreat = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getvul() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities`,
      };
      this.$http(options)
        .then((res) => {
          this.allVulnerabilities = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showModal(itemid) {
      this.riskDeleteID = itemid;
      this.$refs["modal-danger"].show();
    },
    editRisk(editData) {
      this.$router.push({ name: "editrisk", params: { id: editData._id } });
    },
    getSearchByName(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_ONECSF_API_URL +
            `/risks?name=${this.searchResult}&vulnerability_id=${this.selectedvul}&threat_id=${this.selectedthreat}&asset_id=${this.selectedassetcat}`,
          params: { page: num },
        };
        this.$http(options)
          .then((res) => {
            this.allRisk = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.rows = this.lastPage * 10;
            console.log(this.currentPage, this.lastPage, this.rows);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addRisk() {
      this.$router.push({ name: "addrisk" });
    },
    viewRisk(id) {
      this.$router.push({ name: "viewrisk", params: { id: id } });
    },
    deleteRisk(id) {
      id = this.riskDeleteID;
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/risks/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.getRisk();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRisk(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_ONECSF_API_URL + `/risks`,
          params: { page: num },
        };
        this.$http(options)
          .then((res) => {
            this.allRisk = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.rows = this.lastPage * 10;
            console.log(this.currentPage, this.lastPage, this.rows);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getVulnerabilities() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities`,
      };
      return this.$http(options)
        .then((res) => {
          return res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeDestroy() {
    // this.$root.$off('addRiskData')
  },
};
</script>

<style>
.user-select {
  user-select: none;
}
.header {
  font-size: 20px;
  font-weight: 600;
}
.align-center-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.align-center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.padding-20 {
  padding: 20px;
}
/* .table:not(.table-dark):not(.table-light) 
thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) 
tfoot:not(.thead-dark) th{
    background-color: #f3f2f7 !important;
    color:#6e6b7b
} */
.transpent {
  background-color: rgba(130, 134, 139, 0.12) !important;
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
.delete-color {
  color: #ea5455 !important;
}
.icon-box-new-risk {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}
.mr-bottom {
  margin-bottom: 10px;
}
.pointer {
  cursor: pointer;
}
.validation {
  color: red;
  font-size: 12px;
  position: relative;
  top: -10px;
}
.table th,
.table td {
  border-top: 1px solid #e3e0e9;
}
.rowgroup tr:hover {
  background-color: #f6f6f9;
  cursor: pointer;
}
.text-align-right {
  text-align: right;
}
.modal .modal-header .close {
  position: relative;
  top: 4px;
  left: -10px;
}

th {
  background: #472183 !important;
  color: #fff;
}
</style>
